import { Box, Image, VStack, Text, HStack, Flex } from "native-base";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import React, { useEffect, useRef, useState } from "react";
import { generateSignature } from "../../graphql/shared";
import { useReactiveVar } from "@apollo/client";
import { __zoomLink__ } from "../../graphql/policies";
import { __childName__ } from "../../graphql/policies";
import { Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { LoginStackParamList } from "../navigation/navigation";
import { StackNavigationProp } from "@react-navigation/stack";
import "../../assets/style.css";

const windowDimensions = Dimensions.get("window");
const screenDimensions = Dimensions.get("screen");
const client = ZoomMtgEmbedded.createClient();
export const Meet = () => {
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
  const ref = useRef<any>(null);
  const zoomLink = useReactiveVar(__zoomLink__);
  const childName = useReactiveVar(__childName__) || "";
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
    screen: screenDimensions,
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions({ window, screen });
      }
    );
    return () => subscription?.remove();
  });

  useEffect(() => {
    client
      .init({
        zoomAppRoot: ref.current,
        language: "en-US",
        customize: {
          video: {
            isResizable: true,
          },
        },
      })
      .then();

    client.on("connection-change", (payload) => {
      if (payload.state == "Closed") {
        navigation.navigate("Qrcode");
        console.log("connection-change", payload);
      }
    });
  }, []);

  return (
    <VStack
      width={"full"}
      height={"100vh"}
      paddingX={"10"}
      alignItems={"center"}
      overflowY={"hidden"}
    >
      <iframe
        id={"ZoomWindow"}
        ref={ref}
        src={`https://zoom.us/wc/join/${zoomLink}`}
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          height: "100%",
          width: "100%",
          border: "none",
        }}
        sandbox="allow-top-navigation allow-scripts allow-popups allow-same-origin allow-forms"
        allow="camera *;microphone *"
        allowFullScreen={true}
      ></iframe>
    </VStack>
  );
};
