import React from "react";
import { NativeBaseProvider } from "native-base";
import { useFonts } from "expo-font";
import { Qrcode } from "./src/screens/Login/Qrcode";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { apolloClient } from "./graphql/client";
import { ApolloProvider } from "@apollo/client";
import { linking } from "./src/navigation/navigation";
import { Meet } from "./src/screens/Meet";
const Stack = createNativeStackNavigator();

const uri = "Test Site";
console.log(uri);

export default function App() {
  const [fontsLoaded] = useFonts({
    "NotoSansTC-Regular": require("./assets/fonts/Noto_Sans_TC/NotoSansTC-Regular.woff"),
    "NotoSansTC-Medium": require("./assets/fonts/Noto_Sans_TC/NotoSansTC-Medium.woff"),
    "NotoSansTC-Bold": require("./assets/fonts/Noto_Sans_TC/NotoSansTC-Bold.woff"),
    "NotoSansKR-Medium": require("./assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.woff"),
    "Lexend-Regular": require("./assets/fonts/Lexend/Lexend-Regular.woff"),
    "Lexend-Medium": require("./assets/fonts/Lexend/Lexend-Medium.woff"),
    "Lexend-SemiBold": require("./assets/fonts/Lexend/Lexend-SemiBold.woff"),
    "Roboto-Regular": require("./assets/fonts/Roboto/Roboto-Regular.woff"),
  });
  if (!fontsLoaded) {
    return null;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <NativeBaseProvider>
        <NavigationContainer linking={linking}>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Qrcode" component={Qrcode} />
            {/*<Stack.Screen*/}
            {/*    name="CopyModal"*/}
            {/*    component={() => <CopyModal copyName={'test'} />}*/}
            {/*/>*/}
            <Stack.Screen name="Meet" component={Meet} />
          </Stack.Navigator>
        </NavigationContainer>
      </NativeBaseProvider>
    </ApolloProvider>
  );
}
