// https://reactnavigation.org/blog/2020/05/16/web-support/
export const linking = {
  prefixes: ["https://video-englisgh-service-front-end.azurewebsites.net"],
  config: {
    screens: {
      LoginHome: "LoginHome",
      Qrcode: "Qrcode",
      Meet: "Meet",
    },
  },
};

export type LoginStackParamList = {
  LoginHome: undefined;
  Qrcode: undefined;
  Meet: undefined;
};
