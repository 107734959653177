import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { checkQrcode, getQrcode } from "../../../graphql/requests/getQrcode";
import { Text } from "native-base";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import {
  __isTimerStop__,
  __qrCode__,
  __zoomLink__,
  __childName__,
} from "../../../graphql/policies";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { LoginStackParamList } from "../../navigation/navigation";

export const MyTimer = ({ expiryTimestamp }: { expiryTimestamp: Date }) => {
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
  const client = useApolloClient();
  const qrCode = useReactiveVar(__qrCode__);
  const zoomLink = useReactiveVar(__zoomLink__);
  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn("onExpire called");
      __isTimerStop__(true);
    },
  });

  if (isRunning) {
    client
      .query({
        query: checkQrcode,
        variables: { qrCode: qrCode },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        console.log(response.data);
        __zoomLink__(response.data.zoomLink.zoomLink);
        __childName__(response.data.zoomLink.childName);
      });
  }

  // 檢查 zoomlink是否有效
  useEffect(() => {
    if (zoomLink !== "" && zoomLink?.length && zoomLink?.length > 5) {
      __isTimerStop__(true);
      // navigation.navigate("Meet");
    }
  }, [zoomLink]);

  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 30);
    restart(time);
  }, []);
  return (
    <Text
      textAlign={"center"}
      fontFamily={"Lexend-Medium"}
      fontSize={"xl"}
      letterSpacing={".4"}
      zIndex={"2"}
    >
      {minutes}:{seconds}
    </Text>
  );
};
