import { gql } from "@apollo/client";

export const getQrcode = gql`
  query GetQrcode {
    qrcode(input: {})
      @rest(
        type: "Qrcode"
        method: "POST"
        path: "clubservice/go-go-live/qr-code"
      ) {
      qrCode
    }
  }
`;

export const checkQrcode = gql`
  query CheckQrcode {
    zoomLink(qrCode: $qrCode)
      @rest(
        type: "ZoomLink"
        method: "GET"
        path: "clubservice/go-go-live/:qrCode/zoom-link"
      ) {
      zoomLink
      childName
    }
  }
`;
