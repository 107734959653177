import React, { ReactNode, useEffect, useState } from "react";
import { Box, Image, VStack, Text, HStack, Pressable } from "native-base";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { getQrcode } from "../../../graphql/requests/getQrcode";
import { MyTimer } from "./Timer";
import {
  __isTimerStop__,
  __qrCode__,
  __zoomLink__,
  __childName__,
} from "../../../graphql/policies";
import { CopyModal } from "./CopyModal";

export const Qrcode = ({ children }: { children?: ReactNode }) => {
  const client = useApolloClient();
  const isTImerStop = useReactiveVar(__isTimerStop__);
  const [qrcode, setQrcode] = useState<string | null>(null);
  const [time, setTime] = useState<Date>(new Date());
  const childName = useReactiveVar(__childName__) || "";
  const zoomLink = useReactiveVar(__zoomLink__) || "";
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (!childName && !zoomLink) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [childName, zoomLink]);

  useEffect(() => {
    client
      .query({ query: getQrcode, fetchPolicy: "network-only" })
      .then((response) => {
        const qrCode = response.data.qrcode.qrCode;
        setQrcode(qrCode);
        console.log(qrCode);
        __qrCode__(qrCode);
      });
    const time = new Date();
    time.setSeconds(time.getSeconds() + 15);
    setTime(time);
  }, [isTImerStop]);

  const [status, setStatus] = React.useState({});

  return (
    <Box
      bg={"#FEF2E9"}
      width={"full"}
      height={"auto"}
      justifyItems={"center"}
      alignItems={"center"}
      pt={"15"}
    >
      <CopyModal
        copyName={childName}
        isOpen={showModal}
        setShowModal={setShowModal}
      />
      {/*{isTImerStop && <Meet/>}*/}
      <Image
        size={"full"}
        resizeMode={"cover"}
        source={{ uri: require("../../../assets/imgs/qr-code-bg.svg") }}
        position={"fixed"}
        bottom={"-40%"}
      />
      <VStack
        maxWidth={"full"}
        width={"full"}
        paddingBottom={"9"}
        paddingTop={"6"}
        alignItems={"center"}
      >
        <Image
          size={"212"}
          height={"36px"}
          resizeMode="contain"
          source={{ uri: require("../../../assets/imgs/logo-noBreak.svg") }}
        />
        <HStack alignItems={"center"} marginTop={"16px"}>
          <Image
            size={"40px"}
            marginRight={".6em"}
            resizeMode="contain"
            source={{ uri: require("../../../assets/imgs/ggl-services.svg") }}
          />
          <Text fontFamily={"Lexend-Medium"} fontSize={"xl"} color={"#333"}>
            Welcome to Go, Go Live!
          </Text>
        </HStack>
      </VStack>

      <HStack
        bg={"#fff"}
        rounded={"lg"}
        width={"1040"}
        paddingLeft={"47px"}
        paddingTop={"24px"}
        paddingBottom={"36px"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Box alignItems={"flex-start"} justifyContent={"center"}>
          <Image
            source={{
              uri: require("../../../assets/imgs/ggl-introduction.gif"),
            }}
            width={"600"}
            height={"450"}
            resizeMode={"contain"}
            alt={"ggl-introduction"}
            marginBottom={"75px"}
          />
          <Text
            textAlign={"left"}
            letterSpacing={".4"}
            lineHeight={"24"}
            marginTop={"-3em"}
            marginBottom={"12px"}
            color={"#333"}
            fontSize={"xl"}
            fontFamily={"NotoSansTC-Medium"}
            fontWeight={"500"}
          >
            打開 <Text fontFamily={"Lexend-Medium"}>Club App</Text>
            ，點擊您報名的
            <Text fontFamily={"Lexend-Medium"}> Go, Go Live! </Text>
            活動卡片<Text fontFamily={"Lexend-Medium"}> QR Code</Text>。
            <br />
          </Text>
          <Text
            textAlign={"left"}
            letterSpacing={".4"}
            lineHeight={"24"}
            color={"#333"}
            fontSize={"xl"}
            fontFamily={"NotoSansKR-Medium"}
            fontWeight={"500"}
          >
            클럽앱에서 '
            <Text
              textAlign={"left"}
              letterSpacing={".4"}
              lineHeight={"24"}
              color={"#333"}
              fontSize={"xl"}
              fontFamily={"Lexend-Medium"}
              fontWeight={"500"}
            >
              QR
            </Text>
            인증'을 해주세요.
          </Text>
        </Box>

        <Box alignItems={"center"} width={"405"} justifyContent={"center"}>
          {isTImerStop ? (
            <Pressable
              onPress={() => {
                __isTimerStop__(false);
                __zoomLink__(null);
              }}
              marginLeft={"-30px"}
            >
              <Image
                margin={"auto"}
                size={"180"}
                resizeMode="contain"
                source={{ uri: require("../../../assets/imgs/renew.svg") }}
                marginTop={"17px"}
                marginBottom={"58px"}
              />

              <Text
                fontFamily={"NotoSansTC-Medium"}
                fontSize={"xl"}
                textAlign={"center"}
                fontWeight={"500"}
              >
                重新取得&nbsp;
                <Text
                  fontFamily={"Lexend-Medium"}
                  fontSize={"xl"}
                  textAlign={"center"}
                >
                  QR Code
                </Text>
              </Text>
              <Text
                fontFamily={"NotoSansKR-Medium"}
                fontSize={"xl"}
                textAlign={"center"}
                fontWeight={"500"}
              >
                <Text
                  fontFamily={"Lexend-Medium"}
                  fontSize={"xl"}
                  textAlign={"center"}
                  fontWeight={"500"}
                >
                  QR
                </Text>
                코드 새로고침
              </Text>
            </Pressable>
          ) : (
            <Pressable marginLeft={"-30px"}>
              <Image
                shadow={2}
                marginTop={"-1.58em"}
                marginBottom={"32px"}
                source={{
                  uri: `https://qrcode.tec-it.com/API/QRCode?data=${qrcode}`,
                }}
                alt="Alternate Text"
                size="236"
                zIndex={"1"}
              />
              <MyTimer expiryTimestamp={time}></MyTimer>
            </Pressable>
          )}
        </Box>
        <Image
          size={"45%"}
          resizeMode="contain"
          source={{ uri: require("../../../assets/imgs/Zippy_5.svg") }}
          position={"absolute"}
          right={"-12%"}
          bottom={"-20%"}
        />
      </HStack>
      <VStack marginTop={"6%"} alignItems={"center"}>
        <Image
          size={"sm"}
          resizeMode="contain"
          source={{ uri: require("../../../assets/imgs/Footer Logo.svg") }}
        />
        <Text
          color={"#595857"}
          fontFamily={"Roboto-Regular"}
          fontSize={"sm"}
          marginBottom={"10%"}
        >
          Copyright © 2023 World Family English Ltd.
        </Text>
      </VStack>
    </Box>
  );
};
