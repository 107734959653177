import { makeVar } from "@apollo/client";
import { TypePolicies } from "@apollo/client/cache/inmemory/policies";

export const __qrCode__ = makeVar<string | null>(null);
export const __isTimerStop__ = makeVar<boolean>(false);
export const __zoomLink__ = makeVar<string | null>(null);

export const __popup__ = makeVar<boolean>(false);
export const __childName__ = makeVar<string | null>(null);
export const policies: TypePolicies = {
  Query: {
    fields: {
      __qrCode__: {
        read() {
          return __qrCode__();
        },
      },
      __isTimerStop__: {
        read() {
          return __isTimerStop__();
        },
      },
      __zoomLink__: {
        read() {
          return __zoomLink__();
        },
      },
      __childName__: {
        read() {
          return __childName__();
        },
      },
    },
  },
};
