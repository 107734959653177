import { Button, HStack, Image, Text, View } from "native-base";
import { StyleSheet, Modal, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import Clipboard from "@react-native-clipboard/clipboard";
import "../../../assets/style.css";
import { useReactiveVar } from "@apollo/client";
import { __popup__ } from "../../../graphql/policies";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { LoginStackParamList } from "../../navigation/navigation";
interface CopyProps {
  copyName: string;
  isOpen: boolean;

  setShowModal: (isShow: boolean) => void;
}

export const CopyModal = ({ copyName, isOpen, setShowModal }: CopyProps) => {
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
  const [copyPopup, setCopyPopup] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const showPopup = useReactiveVar(__popup__);
  const handleNextPress = () => {
    navigation.navigate("Meet");
    setShowModal(false);
  };
  const handleCopyPress = async () => {
    try {
      await Clipboard.setString(copyName);
      setIsCopied(true);
    } catch (error) {
      console.log("Copy Fail");
    }
  };

  return (
    <Modal visible={isOpen} animationType="fade" transparent={true}>
      <View style={style.overlay}>
        <View style={style.copyWrap}>
          <Text style={style.welcomeTitle}>
            你好，{copyName}請複製你的名字，
            <br />
            並於活動登入頁面貼上。
            <br />
            안녕하세요{copyName}회원님!
            <br />
            세션을 시작하기 위해 참석자의 이름을 복사하고 로그인 페이지에
            붙여넣기 해주세요.
          </Text>
          <HStack style={style.copyBtnWrap}>
            <Text style={style.copyName}>{copyName}</Text>
            <TouchableOpacity
              style={[style.copyBtn, isCopied && style.copiedBtn]}
              onPress={handleCopyPress}
            >
              <Text style={[style.copyBtnTxt, isCopied && style.copiedBtnTxt]}>
                {isCopied ? "Copied!" : "Copy"}
              </Text>
            </TouchableOpacity>
          </HStack>
          <Button style={style.nextBtn} onPress={handleNextPress}>
            <Text style={style.nextBtnTxt}>Next</Text>
          </Button>
          <Image
            style={style.modalZippy}
            source={require("../../../assets/imgs/Modal-zippy.svg")}
            alt={"Zippy"}
            size={"lg"}
          />
        </View>
      </View>
    </Modal>
  );
};

const style = StyleSheet.create({
  overlay: {
    backgroundColor: "rgba(35, 45, 74, 0.7);",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  copyWrap: {
    position: "relative",
    margin: "auto",
    maxWidth: 480,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    paddingHorizontal: 40,
    paddingTop: 32,
    paddingBottom: 24,
    alignContent: "center",
  },
  welcomeTitle: {
    fontFamily: "NotoSansTC-Regular",
    fontSize: 20,
    lineHeight: 42,
    letterSpacing: 0.4,
    color: "#333333",
    textAlign: "center",
  },
  copyName: {
    fontFamily: "Lexend-Medium",
    fontSize: 20,
    letterSpacing: 0.4,
    color: "#333",
  },
  copyBtnWrap: {
    maxWidth: 400,
    minWidth: 325,
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 16,
    marginBottom: 16,
  },
  copyBtn: {
    width: 113,
    borderRadius: 30,
    backgroundColor: "#FF5D55",
    paddingVertical: 18,
    paddingHorizontal: 30.5,
    borderWidth:0
  },
  copiedBtn: {
    backgroundColor: "rgba(0,0,0,0)",
    borderWidth: 1,
    borderColor: "#959191",
  },
  copyBtnTxt: {
    display: "flex",
    color: "#fff",
    fontFamily: "Lexend-Medium",
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: 0.4,
    alignContent: "center",
    alignSelf: "center",
  },
  copiedBtnTxt: {
    color: "#959191",
  },
  nextBtn: {
    width: 113,
    height: 60,
    margin: "auto",
    borderRadius: 30,
    backgroundColor: "#FF5D55",
    paddingVertical: 12,
    paddingHorizontal: 32,
  },
  nextBtnTxt: {
    color: "#fff",
    fontFamily: "Lexend-Medium",
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: 0.4,
  },
  modalZippy: {
    position: "absolute",
    bottom: 0,
    left: 22,
  },
});
