// https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#overview
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { policies } from "./policies";

const restLink = new RestLink({
  uri: "https://api.worldfamilyenglish.com/",
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: policies,
  }),
  link: restLink,
});
